import "./style.scss";

import React, { FC } from "react";
import { Link } from "react-router-dom";

import Logo from "../../../../public/img/kb_logo.svg";
import SearchBar from "../SearchBar";

const Header: FC = () => {
  return (
    <div className="kb-header">
      <Link to="/">
        {" "}
        <img id="kbLogo" alt="Knowledge Base Logo" src={Logo as string} />{" "}
      </Link>
      <SearchBar />
    </div>
  );
};

export default Header;
