import { lazy } from "react";

/**
 * For every partial we want to support we will add a new entry here
 * The key represents the partial name used in Lego
 * e.g. for {{ cms:partial:shared/editbutton }} the key will be "editbutton"
 * The value will be the absolute path for the corrensponding partial created in react
 */

/* istanbul ignore next */
export const PARTIALS_MAP: Record<string, any> = {
  editbutton: lazy(() => import("modules/components/partials/EditButton")),
};
