import { AppLayout } from "common/components";
import ApplicationFavicon from "img/favicon16.png";
import Footer from "modules/components/Footer";
import Header from "modules/components/Header";
import React, { FunctionComponent } from "react";
import Favicon from "react-favicon";

import Router from "./view/Routes";

const App: FunctionComponent = () => {
  return (
    <div className="awsui">
      <Favicon url={ApplicationFavicon} />

      <Header />
      <AppLayout
        className="awsui-util-no-gutters"
        content={<Router />}
        maxContentWidth={1024}
        footerSelector=".kb-footer"
        headerSelector=".kb-header"
        navigationHide={true}
        navigationOpen={false}
        toolsHide
      />
      <Footer />
    </div>
  );
};

export default App;
