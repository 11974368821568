import "./style.scss";

import React, { FC } from "react";
import { Link } from "react-router-dom";

const Footer: FC = () => {
  return (
    <div className="kb-footer">
      <Link to="/sitemap">Sitemap</Link>
    </div>
  );
};

export default Footer;
