import React, { FC } from "react";

const SearchBar: FC = () => {
  return (
    <div className="kbSearchBar">
      <input type="text" placeholder="What can we help you with?"></input>
    </div>
  );
};

export default SearchBar;
