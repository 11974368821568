import { AMAZON_PAGE_UI_SRC, AMAZON_UI_SRC, JQUERY_SRC } from "helper/constants";
import Fetcher from "helper/fetcher";
import { PARTIALS_MAP } from "helper/partials";
import React, { FC, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import InternalServerErrorPage from "view/Page/InternalServerError";
import NotFoundPage from "view/Page/NotFound";

import RenderingHelper, { HttpResponse } from "../../../helper/rendering-helper";
import LoadingScreen from "../LoadingScreen";

const LegoPage: FC = () => {
  const [page, setPage] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [assetsLoad, setAssetsLoad] = useState(false);
  const PARTIALS_REGEX = /{{[[\s]]*cms:partial:shared\/([^ }]*)[[\s]]*}}/g;

  useEffect(() => {
    fetchPage();
  }, []);

  const fetchPage = () => {
    setLoading(true);
    const path = window.location.pathname + window.location.search;
    RenderingHelper.getPage(path)
      .then(([pageContent, status]) => {
        setLoading(false);
        switch (status) {
          case HttpResponse.Success: {
            const newPage = pageContent.replace(PARTIALS_REGEX, "<$1/>");
            setPage(() =>
              ReactHtmlParser(newPage, {
                transform: (node, index) => {
                  // replacing <a> tags with react router Link elements. Needed for routing in SPA context
                  if (node.type === "tag" && node.name === "a") {
                    return <Link to={node.attribs.href}>{node.children[0].data}</Link>;
                  }
                  // replacing the partial tags with react elements
                  if (node.type === "tag" && PARTIALS_MAP[node.name]) {
                    const ReactElement = PARTIALS_MAP[node.name];
                    return (
                      <Suspense fallback={null} key={index}>
                        <ReactElement />
                      </Suspense>
                    );
                  }
                },
              })
            );
            break;
          }
          case HttpResponse.NotFound: {
            setPage(<NotFoundPage />);
            break;
          }
          case HttpResponse.InternalServerError: {
            setPage(<InternalServerErrorPage />);
            break;
          }
          default: {
            setPage(<InternalServerErrorPage />);
            break;
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setPage(<InternalServerErrorPage />);
      });
  };

  /**
   * This is a temporary fix to load AUI assets. Lego assets are expecting the AmazonUI and AmazonPageUI to be already exxecuted
   * when they load. AmazonUI and AmazonPageUI javascripts have a 2 seconds latency when they load, so we need to wait 2 seconds
   * before loading the lego asstes.
   * https://w.amazon.com/bin/view/Advertising/ACT/A20M/AmazonUIJSLatency/
   */
  setTimeout(() => {
    setAssetsLoad(true);
  }, 2000);

  return (
    <>
      <Helmet>
        <script src={AMAZON_UI_SRC} type="text/javascript" />
        <script src={AMAZON_PAGE_UI_SRC} type="text/javascript" />
        <script src={JQUERY_SRC}></script>
        <link rel="stylesheet" type="text/css" href={Fetcher.getLegoStylesUrl()} />
      </Helmet>
      {assetsLoad ? <Helmet>{<script src={Fetcher.getLegoJavascriptUrl()} type="text/javascript" />}</Helmet> : ""}
      <div className="lego-page-content">
        <LoadingScreen show={loading} />
        {page}
      </div>
    </>
  );
};

export default LegoPage;
