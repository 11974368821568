import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import LegoPage from "../../modules/components/LegoPage";

const Router: FC = () => (
  <Switch>
    <Route path="/*" render={(props) => <LegoPage {...props} key={Date.now()} />} />
  </Switch>
);

export default Router;
