import {
  ADA_API_URL,
  ADA_LEGO_ENDPOINT,
  API_URL,
  LEGO_ASSET_FILE_NAME,
  LEGO_ASSETS_ROOT_URL,
  PROD_WEBSITE_URL,
  USER,
} from "./constants";

enum Stages {
  Prod = "prod",
  Beta = "beta",
  Gamma = "gamma",
  Ada = "ada",
}

enum AssetsType {
  CSS = "CSS",
  JS = "JS",
}

export default class Fetcher {
  static getStage(): string {
    const hostname = window.location.hostname;
    return hostname.startsWith("beta")
      ? Stages.Beta
      : hostname.startsWith("gamma")
      ? Stages.Gamma
      : hostname.startsWith(PROD_WEBSITE_URL)
      ? Stages.Prod
      : Stages.Ada;
  }

  static getApiUrl(env: string): string {
    return env === Stages.Ada ? Fetcher.getAdaApiUrl() : API_URL[env];
  }

  static getAdaApiUrl(): string {
    // TODO: add os module to fetch username; use the ada domain name when DNS implemented
    return ADA_API_URL(USER);
  }

  static getAdaLegoUrl(): string {
    // TODO: add os module to fetch username; use the ada domain name when DNS implemented
    return ADA_LEGO_ENDPOINT(USER);
  }

  private static getLegoAssetsUrl(type: AssetsType): string {
    const assetName =
      type === AssetsType.CSS
        ? `${LEGO_ASSET_FILE_NAME}.css`
        : type === AssetsType.JS
        ? `${LEGO_ASSET_FILE_NAME}.js`
        : "";
    return `${LEGO_ASSETS_ROOT_URL}/${assetName}`;
  }

  static getLegoStylesUrl(): string {
    return Fetcher.getLegoAssetsUrl(AssetsType.CSS);
  }

  static getLegoJavascriptUrl(): string {
    return Fetcher.getLegoAssetsUrl(AssetsType.JS);
  }
}
