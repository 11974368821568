import sentryFetch from "@amzn/sentry-fetch";

export enum HttpMethod {
  Get = "GET",
}

export default class HttpClient {
  static getData = (url: string): Promise<Response> => {
    return sentryFetch(url, {
      method: HttpMethod.Get,
      sentryOptions: {
        followMidwayStepUp: true,
        debug: true,
        preserveRedirectUri: true,
        followMidwayStepUpOptions: {
          mode: "modal",
        },
      },
      credentials: "include",
    });
  };
}
