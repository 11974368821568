import { Container, Header } from "@amzn/awsui-components-react";
import React, { FC } from "react";

const InternalServerErrorPage: FC = () => {
  return (
    <Container header={<Header variant="h2">Internal server error!</Header>}>
      Sorry, something went wrong. Please try again later!
    </Container>
  );
};

export default InternalServerErrorPage;
