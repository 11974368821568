import "@amzn/awsui-global-styles";
import "common/styles/style";

import App from "App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

document.body.style.margin = "0";
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("app")
);
