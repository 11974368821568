export const ADA_API_URL = (username: string) => `https://api.${username}.beta.portal.it.a2z.com`;
export const ADA_LEGO_ENDPOINT = (username: string) =>
  `https://${username}.aka.corp.amazon.com:8443/collections/${USER_COLLECTION_MAP[username]}/documents/`;

export const USER_COLLECTION_MAP: Record<string, string> = {
  rusuna: "vnemes-local-itportal",
  tstefana: "tstetefana-lego-kb",
  etruta: "etruta-lego-kb",
};

export const API_URL: Record<string, string> = {
  beta: "https://api.beta.portal.it.a2z.com",
  gamma: "https://api.gamma.portal.it.a2z.com",
  prod: "https://api.portal.it.a2z.com",
};
export const LEGO_ENDPOINT: Record<string, string> = {
  beta: "https://beta.lego.a2z.com/collections/kb-portal-lego-beta/documents/",
  gamma: "https://na.lego.a2z.com/collections/kb-portal-lego-gamma/documents/",
  prod: "https://na.lego.a2z.com/collections/kb-portal-lego-prod/documents/",
};

export const PROD_WEBSITE_URL = "kb.it.a2z.com";
export const LEGO_ASSETS_ROOT_URL = "https://d3216uwaav9lg7.cloudfront.net";

export const LEGO_ASSET_FILE_NAME = "assets-KB";
export const AMAZON_UI_SRC =
  "https://images-na.ssl-images-amazon.com/images/I/81jr7UyxecL._RC%7C31+mDBETfKL.js,81Kgu6chTML.js,01ErspE30fL.js,31lpj1pPXEL.js,11fKXuXeWsL.js,512u7d882AL.js,41pF5pZDe+L.js,71zNQH36EkL.js,31yqywWRwtL.js,01qBV0ro0lL.js,21hCazhqKsL.js,21vW4A0l0BL.js,41LkAGaJlKL.js,213Dk1HPdeL.js,81eWaz0-yrL.js,1163r5YpAGL.js,01+ZphkXPOL.js,01oj-Oza3zL.js,41zn8Nwz2LL.js,01NvnKKl5BL.js,01L+bD75cvL.js_.js";
export const AMAZON_PAGE_UI_SRC = "https://images-na.ssl-images-amazon.com/images/I/31Y9lqhQ9OL.js";
export const JQUERY_SRC = "https://m.media-amazon.com/images/G/01/oneg/assets/3.2.6/js/jquery.min.js";

export const API_RENDERING_PATH = "/render/content/kb";
export const API_EDIT_URL_PATH = "/edit/kb";

export const MAIN_PAGE_TITLE = "ITS Multitenant Website";

export const COOKIES_USERNAME = "username";
export const COOKIE_ADMIN = "admin";
export const HEADER_IS_ADMIN = "isAdmin";
export const HEADER_X_FORWARDED_USER = "x-forwarded-user";

/* To be removed once we can get the user dynamically for development*/
export const USER = "etruta";
